import Repository from '@/modules/common/utils/Repository.js'
import store from '@/store'

export default {
  downloadFile(projectId, filename) {
    // to download binary data we net to set the responseType: 'arraybuffer'
    // https://stackoverflow.com/a/50220546/3969110
    return Repository.dbService.get('ingestion/get_project_file', {
      params: {
        'bot_name': store.getters.getInstance,
        'project_id': projectId,
        'filename': filename
      },
      responseType: 'arraybuffer'
    })
  },
  uploadProjectFiles(formData, projectId=undefined) {
    let params = projectId ? { 'project_id': projectId } : {}
    params['bot_name'] = store.getters.getInstance
    // TODO: set the bot_name as a parameter, because server side gives a error
    // if is not added (should betake it from the formData)
    return Repository.dbService.post('ingestion/upload_project_files', formData, {
      params: params,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  },
  uploadFileFromUrl(url) {
    return Repository.dbService.post('ingestion/upload_project_file_from_url', null, {
      params: {
        bot_name: store.getters.getInstance,
        file_url: url
      }
    })
  },
  buildFilePreviewUrl(projectId, docId, docFilename) {
    var params = docId ? `&doc_id=${docId}`: ''
    params += docFilename ? `&doc_filename=${docFilename}`: ''
    return `${process.env.VUE_APP_DB_SERVICE_API_URL}ingestion/get_project_preview/?bot_name=${store.getters.getInstance}&project_id=${projectId}${params}`
  },
  getPreviewData(projectId, docId, docFilename, botId) {
    return Repository.dbService.get('ingestion/get_project_preview', {
      params: {
        'bot_name': botId,
        'project_id': projectId,
        'doc_id': docId,
        'doc_filename': docFilename
      },
      responseType: 'arraybuffer'
    })
  },
  getBasePipelines() {
    return Repository.dbService.get('/ingestion/get_base_pipelines')
  },
  buildFileUrl(botName, projectId, filename) {
    // avoid error if the filename have special or special chars
    filename = encodeURIComponent(filename);
    return `${process.env.VUE_APP_DB_SERVICE_API_URL}ingestion/get_project_file/?bot_name=${botName}&project_id=${projectId}&filename=${filename}`
  }
}