<template>
  <div style="width:100%; min-width:600px">
    <b-img :fluid="true" :src="previewSrc"></b-img>
  </div>
</template>

<script>
import IngestionService from '@/modules/common/services/IngestionService.js'

export default {

  name: "PreviewFile",
  data() {
    return {
      previewSrc: null,
      pollImage: null
    }
  },
  methods: {
    getImageUrl() {
      return IngestionService.buildFilePreviewUrl(this.projectId, this.docId, this.filename)
    },
    startGetPreview() {
      this.pollImage = setInterval(this.getPreviewData, 5000)
    },
    getPreviewData() {
      IngestionService.getPreviewData(this.projectId, this.docId, this.filename, this.$router.currentRoute.params.bot).then(response => {
        let previewData = new Uint8Array(response.data)
        if(previewData.length != 0) {
          previewData = btoa(previewData.reduce((data, byte) => data + String.fromCharCode(byte), ''))
          this.previewSrc = `data:image/jpeg;base64, ${ previewData }`
          this.$emit('preloader-display', false)
          clearInterval(this.pollImage)
        }
      }, error => {
        console.log(error)
        clearInterval(this.pollImage)
      })
    }
  },
  props: {
    projectId: String,
    docId: String,
    filename: String
  },
  beforeDestroy () {
    clearInterval(this.pollImage)
  },
  created() {
    // First call to get a pre uploaded image most quickly, this will destroy the poll if the image was uploaded
    this.getPreviewData()
    // This will start poll the image after 5 seconds if the the method above not destroy it first
    this.startGetPreview()
  }
}
</script>
